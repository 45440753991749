<template>
  <div class='fs16'>
    <div class="tabListHeader pl44 pr27 flex col-center mt6">
      <!-- // -->
      <div class="tabListHeader_after"></div>
      <div class="flex1">设备号</div>
      <div class="w194 text-center">设备位置</div>
      <div class="flex1" style='text-align: right;'>检测次数(次)</div>
    </div>
    <div class="h186" style="overflow: hidden;">
      <div class="roll_box" :style="{'--updateStyle':speend+'s'}" v-if="list.length>0">
        <div class="flex app_table_item ml40 pl4 h45 lh45" v-for="(item,i) in list" :key="i">
          <div class="flex1 table_text">{{item.name}}</div>
          <div class="w194 text-center table_text mr16 ml16">{{item.time}}</div>
          <div class="flex1 text-center" style="">{{item.format}}</div>
        </div>
      </div>
      <div class="text-center lh186" v-else>暂无数据</div>
    </div>
  </div>
</template>

<script>
//设备使用情况
import { situation } from '@/api/wang.js'
import clearTime from '@/mixin/clearTime.js'
export default {
  mixins: [clearTime],
  data () {
    return {
      list: [],
      speend: null
    };
  },

  created () {
    this.situation()
    this.timer = setInterval(() => {
      this.situation()
    }, 180000)
  },

  mounted () { },

  methods: {
    async situation () {
      const { data } = await situation()
      this.list = data
      if (data.length >= 4) {
        this.speend = data.length
        this.list = [...data, ...data]
      } else {
        this.list = data
        this.speend = null
      }
    }
  }
}

</script>
<style lang='scss' scoped>
// .app_table_item:nth-child(even) {
//   background: linear-gradient(
//     90deg,
//     rgba(19, 250, 239, 0.24) 0%,
//     rgba(8, 244, 218, 0) 100%
//   );
//   border-radius: 5px;
// }
.table_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.roll_box {
  animation: move var(--updateStyle) linear infinite;
}
@keyframes move {
  0% {
  }

  100% {
    transform: translateY(-50%);
  }
}
.roll_box:hover {
  animation-play-state: paused;
}
</style>