<template>
  <div class='box flex flex-wrap color-f relative'>
    <div class="box_item1  h106 flex row-column row-center col-center ">
      <div class="fs48 color-q fm-n">{{obj.first||0}}</div>
      <div class="fs14 fw-500">已接种1针(人)</div>
    </div>
    <div class="box_item2  h106 flex row-column row-center col-center">
      <div class="fs48 color-q fm-n">{{obj.second||0}}</div>
      <div class="fs14 fw500">已接种2针(人)</div>
    </div>
    <div class="box_item3  h106 flex row-column row-center col-center">
      <div class="fs48 color-q fm-n">{{obj.third||0}}</div>
      <div class="fs14 fw500">已接种3针(人)</div>
    </div>
    <div class="box_item4  h106 flex row-column row-center col-center">
      <div class="fs48 color-q fm-n">{{obj.unknown+obj.none||0}}</div>
      <div class="fs14 fw500">未接种/未知(人)</div>
    </div>
    <div class="box_center w90 h90 radius90 fs21 fw-500 flex row-column col-center row-center">
      <div>疫苗接</div>
      <div>种情况</div>
    </div>
  </div>
</template>

<script>
//获取设备列表
import { vaccineDetail } from '@/api/wang.js'
import clearTime from '@/mixin/clearTime.js'
export default {
  name: 'shebeiType',
  mixins: [clearTime],
  data () {
    return {
      obj: {
        first: '',
        none: '',
        second: '',
        third: '',
        unknown: ''
      }
    };
  },

  created () {
    this.vaccineDetail()
    this.timer = setInterval(() => {
      this.vaccineDetail()
    }, 180000)
  },

  mounted () {

  },

  methods: {
    async vaccineDetail () {
      const { data } = await vaccineDetail()
      this.obj = data
    }
  }
}

</script>
<style lang='scss' scoped>
.box {
  width: 566px;
  background-color: rgba(0, 0, 0, 0);
  .box_item1,
  .box_item2,
  .box_item3,
  .box_item4 {
    background-color: rgba(0, 0, 0, 0);
    background: url("~@/assets/img/4.0/shebei_item_l_t.png") no-repeat;
    background-size: 100%;
    width: 281px;
  }
  .box_item2 {
    margin-left: 4px;
    background: url("~@/assets/img/4.0/shebei_item_r_t.png") no-repeat;
    background-size: 100%;
  }
  .box_item3 {
    background: url("~@/assets/img/4.0/shebei_item_l_b.png") no-repeat;
    background-size: 100%;
  }
  .box_item4 {
    margin-left: 4px;
    background: url("~@/assets/img/4.0/shebei_item_r_b.png") no-repeat;
    background-size: 100%;
  }
  .box_center {
    position: absolute;
    top: 28%;
    left: 42%;
    background: linear-gradient(
      180deg,
      rgba(20, 76, 69, 0.3) 0%,
      rgba(18, 146, 132, 0.66) 100%
    );
  }
}
</style>