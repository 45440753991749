<template>
  <div class='box flex flex-wrap'>
    <div class="box_item radius8 flex col-center mt20">
      <div class="w83 fs16 flex row-column col-center row-center">
        ＜35℃
      </div>
      <div class="line"></div>
      <div class="flex flex1 row-center col-center color-q">
        <div class="fs48 color-q fm-n mr8">{{lowTemp}}</div>
        <div class="fs16 fw-600">人,{{parseInt(lowTemp/(lowTemp+normalTemp+highTemp)*100)||0||0}}%</div>
      </div>
    </div>
    <div class="box_item radius8 flex col-center mt20">
      <div class="w83 fs16 flex row-column col-center row-center">
        <div class="">35℃</div>
        <div>~</div>
        <div>37.2℃</div>
      </div>
      <div class="line"></div>
      <div class="flex color-q flex1 row-center col-center">
        <div class="fs48 color-q fm-n mr8">{{normalTemp}}</div>
        <div class="fs16 fw-600">人,{{parseInt(normalTemp/(lowTemp+normalTemp+highTemp)*100)||0}}%</div>
      </div>
    </div>
    <div class="box_item radius8 flex col-center mt20">
      <div class="w83 fs16 flex row-column col-center row-center">
        ＞37.2℃
      </div>
      <div class="line"></div>
      <div class="flex color-q flex1 row-center col-center">
        <div class="fs48 color-q fm-n mr8">{{highTemp}}</div>
        <div class="fs16 fw-600">人,{{parseInt(highTemp/(lowTemp+normalTemp+highTemp)*100)||0}}%</div>
      </div>
    </div>
    <div class="box_item radius8 flex col-center mt20">
      <div class="w83 fs16 flex row-column col-center row-center">
        <div>体温异常</div>
        <div> 人数</div>
      </div>
      <div class="line"></div>
      <div class="flex color-q flex1 row-center col-center">
        <div class="fs48 color-q fm-n mr8">{{lowTemp+highTemp}}</div>
        <div class="fs16 fw-600">人,{{parseInt((lowTemp+highTemp)/(lowTemp+normalTemp+highTemp)*100)||0}}%</div>
      </div>
    </div>
  </div>
</template>

<script>
import { temMonitor } from '@/api/wang.js'
import clearTime from '@/mixin/clearTime.js'
export default {
  name: 'tiwenData',
  mixins: [clearTime],
  data () {
    return {
      lowTemp: '',
      allTemp: '',
      highTemp: '',
      normalTemp: ''
    };
  },

  created () {
    //获取今日体温数据监测
    this.temMonitor()
    this.timer = setInterval(() => {
      this.temMonitor()
    }, 180000)
  },

  mounted () { },

  methods: {
    async temMonitor () {
      const { data } = await temMonitor()
      this.lowTemp = data.lowTemp
      this.allTemp = data.allTemp
      this.highTemp = data.highTemp
      this.normalTemp = data.normalTemp
    }
  }
}

</script>
<style lang='scss' scoped>
.box_item {
  width: 48%;
  height: 77px;
  background: linear-gradient(
    90deg,
    rgba(19, 250, 239, 0.3) 0%,
    rgba(8, 244, 218, 0) 100%
  );
  .line {
    width: 1px;
    height: 48px;
    opacity: 0.2;
    border: 2px solid #ffffff;
  }
}
.box_item:nth-child(odd) {
  margin-right: 20px;
}
</style>