<template>
  <div class='box h210'>
    <div class="pt28 flex pl31 row-around">
      <!-- 循环项 -->
      <div class="fs16 flex row-column col-center" v-for="(item,i) in list" :key="i">
        <div class="fs48 fm-n flex col-center" :style="`color:${item.color}`">{{item.num}}<span
            class="fs17 ml5">人</span>
        </div>
        <img :src="item.images" class="w178 h151">
        <div class="diqu">{{item.area}}</div>
      </div>
      <!-- 循环项 -->
    </div>
  </div>
</template>

<script>
import { risk } from '@/api/wang.js'
import clearTime from '@/mixin/clearTime.js'
export default {
  props: ['greenNum'],
  mixins: [clearTime],
  data () {
    return {
      list: [{
        num: null,
        color: '#08F3DA',
        images: require('@/assets/img/4.0/oneRobot/wind_g.png'),
        area: '途径低风险地区'
      }, {
        num: null,
        color: '#F4AD04',
        images: require('@/assets/img/4.0/oneRobot/wind_y.png'),
        area: '途径中高风险地区'
      },
        // {
        //   num: 400,
        //   color: '#E1E1E1',
        //   images: require('@/assets/img/4.0/oneRobot/wind_h.png'),
        //   area: '未知'
        // },
      ]
    };
  },

  created () {
    this.risk()
    this.timer = setInterval(() => {
      this.risk()
    }, 180000)
  },

  mounted () { },

  methods: {
    async risk () {
      const { data } = await risk()
      this.list[0].num = this.greenNum
      this.list[1].num = data.zg
      this.list[2].num = data.wei
    }
  }
}

</script>
<style lang='scss' scoped>
.box {
  width: 100%;
  background: url("~@/assets/img/4.0/oneRobot/7DayBg.png");
  background-size: 100% 100%;
  img {
    margin-top: -25px;
  }
  .diqu {
    margin-top: -40px;
  }
}
</style>