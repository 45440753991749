<template>
  <div class='app_table fs16'>
    <div class="tabListHeader pl32 pr27 flex col-center">
      <div class="tabListHeader_after"></div>
      <div class="w210 flex col-center">
        <div class="w114 tab_name">设备号</div>
        <div class="flex1 text-center">人次</div>
      </div>
      <div class="flex1 text-right">设备位置</div>
    </div>
    <div class="h140 pl32 pr27" style="overflow: hidden;">
      <div class="roll_box" :style="{'--updateStyle':speend+'s'}" v-if="list.length>0">
        <div class='flex col-center h40 lh40 pl6 app_table_item' v-for="(item,i) in list" :key="i">
          <div class="w210 text-center flex col-center">
            <div class="w114 tab_name table_text">{{item.name}}</div>
            ：
            <div class="flex1 text-center pr20"> {{item.format}}人</div>
          </div>
          <div class="w200 ml16 mr16">
            <el-progress :percentage="item.format/totalNum*100" :show-text='false'></el-progress>
          </div>
          <div class="flex1 text-right table_text">{{item.time}}</div>
        </div>
      </div>
      <div class="text-center lh120" v-else>暂无数据</div>
    </div>
  </div>
</template>

<script>
import { abnormal } from '@/api/wang.js'
import clearTime from '@/mixin/clearTime.js'
export default {
  name: 'yichangDate',
  mixins: [clearTime],
  data () {
    return {
      list: [],
      //异常总人数
      totalNum: '',
      speend: null
    };
  },

  created () {
    this.abnormal()
    this.timer = setInterval(() => {
      this.abnormal()
    }, 180000)
  },

  mounted () { },

  methods: {
    abnormal () {
      abnormal().then(res => {
        //计算异常总人数
        this.totalNum = res.data.reduce((total, item) => {
          return total += (item.format) * 1
        }, 0)
        if (res.data.length >= 3) {
          this.speend = res.data.length
          this.list = [...res.data, ...res.data]
        } else {
          this.list = res.data
          this.speend = null
        }
      })
    }
  }
}

</script>
<style lang='scss' scoped>
::v-deep .el-progress-bar__inner {
  background: linear-gradient(270deg, #f7da65 0%, #07ba74 100%);
}
// .app_table_item:nth-child(even) {
//   background: linear-gradient(
//     90deg,
//     rgba(19, 250, 239, 0.24) 0%,
//     rgba(8, 244, 218, 0) 100%
//   );
//   border-radius: 5px;
// }
.roll_box {
  animation: move var(--updateStyle) linear infinite;
  .tab_name {
    text-align: justify;
    text-align-last: justify;
    text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
    white-space: nowrap; /* 强制文本在一行中显示 */
    overflow: hidden; /* 溢出隐藏*/
    text-overflow: ellipsis; /* 文本超出后显示省略号*/
  }
}
@keyframes move {
  0% {
  }
  100% {
    transform: translateY(-50%);
  }
}
.roll_box:hover {
  animation-play-state: paused;
}
.table_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>