<template>
  <div class='box'>
    <div class="tabListHeader flex col-center mt6">
      <!-- // -->
      <div class="tabListHeader_after"></div>
      <div class="text-center w70">姓名</div>
      <div class="text-center w139">今日检测时间</div>
      <div class="text-center w96">健康码</div>
      <div class="text-center w118">体温(℃)</div>
      <div class="text-center w148">设备位置</div>
    </div>
    <div class="pt4 h230" style="overflow: hidden;">
      <div class="roll_box" :style="{'--updateStyle':speend+'s'}" v-if="list.length>0">
        <div class="flex app_table_item h36 lh36 fs16" v-for="item in list" :key="item.id"
          :class="{bgRed:item.healthCode=='红码'}">
          <div class="text-center w70 table_text">{{item.perName}}</div>
          <div class="text-center w139">{{item.temTime}}</div>
          <div class="text-center w96">{{item.healthCode}}</div>
          <div class="text-center w118">{{item.tem}}</div>
          <div class="text-center w148 table_text">{{item.address}}</div>
        </div>
      </div>
      <div class="text-center lh145" v-else>暂无数据</div>
    </div>

  </div>
</template>

<script>
import { todayDefend } from '@/api/wang.js'
import clearTime from '@/mixin/clearTime.js'
export default {
  name: 'todayTestList',
  mixins: [clearTime],
  data () {
    return {
      list: [],
      speend: null
    };
  },

  created () {
    this.todayDefend()
    this.timer = setInterval(() => {
      this.todayDefend()
    }, 180000)
  },

  mounted () { },

  methods: {
    async todayDefend () {
      const { data } = await todayDefend()
      data.forEach(item => {
        item.temTime = item.temTime.substr(11)
        if (item.perName.includes('*')) {
          return item.perName
        } else {
          let name = []
          name.push(...item.perName)
          item.perName = name.length == 2 ? name[0] + '*' : name[0] + '*' + name.slice(-1)
        }
      })
      if (data.length >=6) {
        this.speend = data.length
        this.list = [...data, ...data]
      } else {
        this.list = data
        this.speend = null
      }
    }
  }
}

</script>
<style lang='scss' scoped>
// .app_table_item:nth-child(even) {
//   background: linear-gradient(
//     90deg,
//     rgba(19, 250, 239, 0.24) 0%,
//     rgba(8, 244, 218, 0) 100%
//   );
//   border-radius: 5px;
// }
.roll_box {
  animation: move var(--updateStyle) linear infinite;
}
@keyframes move {
  0% {
  }

  100% {
    transform: translateY(-50%);
  }
}
.roll_box:hover {
  animation-play-state: paused;
}
.table_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bgRed {
  color: #ff384a;
  background: rgba(230, 56, 71, 0.26);
}
</style>