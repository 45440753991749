<template>
  <div class='box flex row-between'>
    <div class="flex row-column col-center" v-for="(item,i) in list" :key="i">
      <div class="fm-n fs48  mb8" :style="`color:${item.color}`">{{item.num}}</div>
      <div class="fs16 fw-500">{{item.title}}</div>
      <img :src="item.image" class="w163 h75 mt-75">
    </div>
  </div>
</template>

<script>

import { todayCheck } from '@/api/wang.js'
import clearTime from '@/mixin/clearTime.js'
export default {
  mixins: [clearTime],
  data () {
    return {
      list: [{
        num: '',
        title: '绿码(人)',
        color: '#08F4DA',
        image: require('@/assets/img/4.0/healthyCodeImg/green.png')
      }, {
        num: '',
        title: '黄码(人)',
        color: '#F99C14',
        image: require('@/assets/img/4.0/healthyCodeImg/yellow.png')
      }, {
        num: '',
        title: '红码(人)',
        color: '#F91429',
        image: require('@/assets/img/4.0/healthyCodeImg/red.png')
      }],
      testNum: '',
      greenNum: null
    };
  },

  created () {
    this.todayCheck()
    this.timer = setInterval(() => {
      this.todayCheck()
    }, 180000)
  },

  mounted () { },

  methods: {
    async todayCheck () {
      const { data } = await todayCheck()
      this.list[0].num = data.greenNum
      this.list[1].num = data.yelloNum
      this.list[2].num = data.redNum
      this.testNum = data.useNum
      this.greenNum = data.greenNum
      this.$emit('getTestNum', this.testNum)
      this.$emit('greenNum', this.greenNum)
    }
  }
}

</script>
<style lang='scss' scoped>
.mt-75 {
  margin-top: -40px;
}
</style>