<template>
  <div class='app_table fs16'>
    <div class="tabListHeader pl32 pr27 flex col-center">
      <div class="tabListHeader_after"></div>
      <div class="w210 flex col-center">
        <div class="w114 tab_name">设备号</div>
        <div class="flex1 text-center">日期</div>
      </div>
      <div class="flex1 text-right">检测人次(次)</div>
    </div>
    <div class="h280 pl32 pr27" style="overflow: hidden;">
      <div class="roll_box" :style="{'--updateStyle':speend+'s'}" v-if="list.length>0">
        <div class='flex col-center h40 lh40 pl6 app_table_item' v-for="(item,i) in list" :key="i">
          <div class="w210 text-center flex col-center">
            <div class="w114 tab_name">{{item.name}}</div>
            ：
            <div class="flex1 text-center pr20"> {{item.time}}</div>
          </div>
          <div class="w200 ml16">
            <el-progress :percentage="item.format/totalPeople*100" :show-text='false'></el-progress>
          </div>
          <div class="flex1 text-right">{{item.format}}</div>
        </div>
      </div>
      <div class="text-center lh105" v-else>暂无数据</div>
    </div>
  </div>
</template>

<script>
import { weekPerson } from '@/api/wang.js'
import clearTime from '@/mixin/clearTime.js'

export default {
  name: 'xiaoduArea',
  data () {
    return {
      list: [],
      speend: null,
      totalPeople: null
    };
  },
  mixins: [clearTime],
  created () {
    this.weekPerson()
    this.timer = setInterval(() => {
      this.weekPerson()
    }, 180000)
  },

  mounted () { },

  methods: {
    async weekPerson () {
      const { data } = await weekPerson()
      data.forEach(item => {
        this.totalPeople += item.format*1
        item.time = item.time.substring(5)
      })
      if (data.length >= 7) {
        this.speend = data.length
        this.list = [...data, ...data]
      } else {
        this.list = data
        this.speend = null
      }
    }
  }
}

</script>
<style lang='scss' scoped>
::v-deep .el-progress-bar__inner {
  background: linear-gradient(270deg, #9565f7 0%, #0757ba 100%);
}
// .app_table_item:nth-child(even) {
//   background: linear-gradient(
//     90deg,
//     rgba(19, 250, 239, 0.24) 0%,
//     rgba(8, 244, 218, 0) 100%
//   );
//   border-radius: 5px;
// }
.roll_box {
  animation: move var(--updateStyle) linear infinite;
  .tab_name {
    text-align: justify;
    text-align-last: justify;
    text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
    white-space: nowrap; /* 强制文本在一行中显示 */
    overflow: hidden; /* 溢出隐藏*/
    text-overflow: ellipsis; /* 文本超出后显示省略号*/
  }
}
@keyframes move {
  0% {
  }
  100% {
    transform: translateY(-50%);
  }
}
.roll_box:hover {
  animation-play-state: paused;
}
</style>