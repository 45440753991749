<template>
  <div>
    <top :shouback="true" />
    <div class='container flex color-f'>
      <!-- 左边的盒子 -->
      <div class="box_l flex1 h-max pt37 color-f">
        <div class="mb36 pl22 pr128">
          <shebeiType />
        </div>
        <!-- 设备使用情况 -->
        <div class="pl30">
          <!-- 标题 -->
          <div class="header_title fs16">
            <div class="yuan"></div>
            <div class="header_ch">设备使用情况</div>
            <div class="header_en">{{"Equipment usage".toUpperCase()}}</div>
          </div>
          <div class="pr138">
            <shebeiUseState />
          </div>
        </div>
        <!-- //设备使用情况 -->
        <!-- 健康码检测情况 -->
        <div class="mt21 pl30">
          <!-- 标题 -->
          <div class="header_title fs16">
            <div class="yuan"></div>
            <div class="header_ch">健康码检测情况</div>
            <div class="header_en">{{"Health code detection".toUpperCase()}}</div>
          </div>
          <div class="pr141">
            <healthyCode class="mt20" @greenNum='getGreenNum' />
          </div>

        </div>
        <!-- //健康码检测情况 -->

        <!-- 体温数据监测 -->
        <div class="mt37 pl30">
          <!-- 标题 -->
          <div class="header_title fs16">
            <div class="yuan"></div>
            <div class="header_ch">体温数据监测</div>
            <div class="header_en">{{"Temperature data monitoring".toUpperCase()}}
            </div>
          </div>
          <div class="pr126">
            <tiwenData />
          </div>
        </div>
        <!-- //体温数据监测 -->
      </div>
      <!-- 左边的盒子 -->

      <!-- 中间的盒子 -->
      <div class="box_center w488 h-max">
        <img src="@/assets/img/4.0/topHeader.png" class="w330 h12 mt23" style="margin:0 auto">
        <!-- 今日检测人数 -->
        <div class="mt30 w-max countNum relative" style="z-index:2">
          <CountFlop style="margin:0 auto" :val='count' title='今日检测人数' suffix='次' />
        </div>
        <!-- //今日检测人数 -->

        <!-- 中间旋转区域 -->
        <div class="center_rotate">
          <centerRotate />
        </div>
        <!-- //中间旋转区域 -->

        <!-- 中间底部的盒子 -->
        <div class="center_b relative" style="z-index:2">
          <centerBottom />
        </div>
        <!-- //中间底部的盒子 -->
      </div>
      <!-- //中间的盒子 -->

      <!-- 右边的盒子 -->
      <div class="box_r flex1 h-max pt57 mr26">
        <!-- 今日异常数据监测 -->
        <div class="pl73 pr22">
          <div class="header_title fs16">
            <div class="yuan"></div>
            <div class="header_ch">今日异常数据监测</div>
            <div class="header_en">
              {{"Today's abnormal data monitoring".toUpperCase()}}</div>
          </div>
          <!-- 组件 -->
          <div class="mt6">
            <yichangDate />
          </div>
        </div>
        <!-- //今日异常数据监测 -->

        <!-- 近一周检测人次 -->
        <div class="pl73 pr22 mt20">
          <div class="header_title fs16 mb6">
            <div class="yuan"></div>
            <div class="header_ch">近一周检测人次</div>
            <div class="header_en">{{"Disinfection area in recent week".toUpperCase()}}</div>
          </div>
          <!-- 组件 -->
          <div>
            <weekTestNum />
          </div>
          <!-- //组件 -->
        </div>
        <!-- //近一周检测人次 -->

        <!-- 7日内途径风险地区情况 -->
        <!-- <div class="pl103 pr22 mt15">
          <div class="header_title fs16 mb6">
            <div class="yuan"></div>
            <div class="header_ch">7日内途径风险地区情况</div>
            <div class="header_en">{{"Situation of risk areas within 7 days".toUpperCase()}}</div>
          </div>
          <div class="pt19">
            <sevenDayState :greenNum='greenNum' />
          </div>
        </div> -->
        <!-- //7日内途径风险地区情况 -->

        <!-- 机器人使用情况 -->
        <div class="pl103 pr22 mt48">
          <div class="header_title fs16 mb6">
            <div class="yuan"></div>
            <div class="header_ch">今日检测记录</div>
            <div class="header_en">{{"Daily detection record".toUpperCase()}}</div>
          </div>
          <!-- 组件 -->
          <div class="mt7">
            <todayTestList />
          </div>
          <!-- //组件 -->
        </div>
        <!-- //机器人使用情况 -->
      </div>
      <!-- //右边的盒子 -->
    </div>
  </div>

</template>

<script>
import top from '@/components/top.vue'
import shebeiType from '@/views/oneRobot/components/shebeiType.vue'
import healthyCode from '@/components/healthyCode'
import tiwenData from '@/components/tiwenData'
import weekTestNum from './components/weekTestNum.vue'
import centerRotate from './components/centerRotate.vue'
import centerBottom from '@/components/centerBottom'
import shebeiUseState from '@/views/oneRobot/components/shebeiUseState.vue'
import yichangDate from './components/yichangDate.vue'
import sevenDayState from './components/sevenDayState.vue'
import todayTestList from './components/todayTestList.vue'
import { todayDefends } from '@/api/wang.js'
import clearTime from '@/mixin/clearTime.js'
import CountFlop from '@/components/CountFlop'
export default {
  mixins: [clearTime],
  components: {
    top, shebeiType, healthyCode,
    tiwenData, weekTestNum, centerRotate, centerBottom, shebeiUseState, yichangDate, sevenDayState, todayTestList, CountFlop
  },
  data () {
    return {
      query: {
        pageNum: 1,
        pageSize: 20
      },
      count: '',
      greenNum: null  //绿码人数
    };
  },

  created () {
    this.todayDefends()
    this.timer = setInterval(() => {
      this.todayDefends()
    }, 180000)
  },

  mounted () { },

  methods: {
    async todayDefends () {
      const { data } = await todayDefends(this.query)
      this.toOrderNum(data.count)
    },
    //转换千分位
    toOrderNum (num) {
      num = num.toString()
      if (num.length < 7) {
        num = '0' + num
        this.toOrderNum(num)
      } else {
        //加逗号
        num = num.slice(1, 4) + ',' + num.slice(4, 7)
        this.count = num
      }
    },
    //绿码人数
    getGreenNum (num) {
      // console.log(111,num);
      this.greenNum = num
    }
  }
}

</script>
<style lang='scss' scoped>
.container {
  width: 100%;
  height: 1004px;
  background: #000;
  perspective: 1800px;
  .box_l {
    transform: rotateY(10deg);
    background: url("~@/assets/img/4.0/box_l.png") no-repeat;
    // background-size: 100% 100%;
    background-size: contain;
    max-width: 720px;
  }
  .box_r {
    transform: rotateY(-10deg);
    background: url("~@/assets/img/4.0/box_r.png") no-repeat;
    // background-size: 100% 100%;
    background-size: contain;
    max-width: 720px;
  }
  .center_rotate {
    margin-top: -70px;
  }
  .center_b {
    margin-left: -70px;
    margin-top: -90px;
  }
}
</style>