<template>
  <div class="w678 h760 rotate_box relative">
    <div class="w120 h125 fs32 color-q center_text text-center pt15">
      防疫数<br>字哨兵
    </div>
    <!-- 最里面 -->
    <div class="w184 h184 chilun_img1"></div>
    <!-- 最外面齿轮 -->
    <div class="w296 h296 chilun_img2 relative color-q">
      <img src="@/assets/img/4.0/center/chilun_img2.png" class="w296 h296">
      <div class="qiu1 w212 h210"></div>
      <div class="qiu1_f w212 h210 text-center pt75 fs27 fw-700">
        <span class="">疫苗接种</span>
        <br>情况
      </div>
      <div class="qiu2 w212 h210"></div>
      <div class="qiu2_f w212 h210 text-center lh210 fs27 fw-700">
        <span class="">核酸监测</span>
      </div>
      <div class="qiu3 w212 h210 "></div>
      <div class="qiu3_f w212 h210 text-center lh210 fs27 fw-700">
        <span class="">健康码</span>
      </div>
      <div class="qiu4 w212 h210 "></div>
      <div class="qiu4_f w212 h210 text-center lh210 fs27 fw-700">
        <span class="">检测记录</span>
      </div>
      <div class="qiu5 w212 h210"></div>
      <div class="qiu5_f w212 h210 text-center lh210 fs27 fw-700">
        <span class="">体温监测</span>
      </div>
    </div>
    <!-- 中间的虚线 -->
    <img src="@/assets/img/4.0/center/chilun_img3.png" class="w228 h228 chilun_img3">
  </div>
</template>

<script>
export default {
  data () {
    return {
    };
  },

  created () { },

  mounted () { },

  methods: {}
}

</script>
<style lang='scss' scoped>
.rotate_box {
  margin-left: -95px;
  background: url("~@/assets/img/4.0/center/center_bg.png") no-repeat;
  .center_text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: url("~@/assets/img/4.0/center/xiaoyuan.png") no-repeat;
    background-size: 100% 100%;
  }
  .chilun_img1 {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url("~@/assets/img/4.0/center/chilun_img1.png") no-repeat;
    background-size: 100% 100%;
    transform: translate(-50%, -50%);
    animation: rotate1 15s linear infinite;
  }
  .chilun_img2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: rotate1 15s linear infinite;
  }
  .chilun_img3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: rotate2 15s linear infinite;
  }
  @keyframes rotate1 {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  @keyframes rotate2 {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(-360deg);
    }
  }
  .qiu1_f,
  .qiu1 {
    position: absolute;
    top: -65%;
    left: 15%;
  }
  .qiu1 {
    background: url("~@/assets/img/4.0/center/qiu.png") no-repeat;
    background-size: 100% 100%;
    animation: rotate3 5s linear infinite;
  }

  .qiu2_f,
  .qiu2 {
    position: absolute;
    top: -14%;
    right: -62%;
    transform: rotate(70deg);
  }
  .qiu2 {
    background: url("~@/assets/img/4.0/center/qiu.png") no-repeat;
    background-size: 100%;
    animation: rotate3 5s linear infinite;
  }
  .qiu3_f,
  .qiu3 {
    position: absolute;
    top: 77%;
    right: -34%;
    transform: rotate(140deg);
  }
  .qiu3 {
    background: url("~@/assets/img/4.0/center/qiu.png") no-repeat;
    background-size: 100%;
    animation: rotate3 5s linear infinite;
  }
  .qiu4_f,
  .qiu4 {
    position: absolute;
    top: 77%;
    left: -34%;
    transform: rotate(-140deg);
  }
  .qiu4 {
    background: url("~@/assets/img/4.0/center/qiu.png") no-repeat;
    background-size: 100%;
    animation: rotate3 5s linear infinite;
  }
  .qiu5_f,
  .qiu5 {
    position: absolute;
    top: -14%;
    left: -62%;
    transform: rotate(-70deg);
  }
  .qiu5 {
    background: url("~@/assets/img/4.0/center/qiu.png") no-repeat;
    background-size: 100%;
    animation: rotate3 5s linear infinite;
  }

  @keyframes rotate3 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }
}
</style>